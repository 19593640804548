
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { ServicePrice } from "@/core/models/ServicePrice"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import { SelectModel } from "@/core/models/SelectModel"
import ApiService from "@/core/services/ApiService"

import { ElForm } from "element-plus"
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { ServiceHierarchy } from "@/core/models/ServiceHierarchy"

export default defineComponent({
  name: "service-price-component",
  components: { ElInputCurrency },
  props: ["id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { id } = toRefs(props)

    const serviceTreeSelectProps = {
      label: "name",
      value: "id",
      children: "childs",
      disabled: data => {
        return !data.isService
      },
    }

    const servicePriceData = ref<ServicePrice>({} as ServicePrice)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const semesterList = ref<SelectModel[]>([])
    const timePeriodList = ref<SelectModel[]>([])
    const serviceList = ref<ServiceHierarchy[]>([])
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isSemesterSelectLoading = ref(false)
    const isTimePeriodSelectLoading = ref(false)
    const isServiceSelectLoading = ref(false)

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      branchId: getRule(RuleTypes.SELECT, "Şube"),
      semesterId: getRule(RuleTypes.SELECT, "Dönem"),
      serviceId: getRule(RuleTypes.SELECT, "Satış Türü"),
      name: getRule(RuleTypes.TEXT50, "Ad"),
      startDate: getRule(RuleTypes.DATE, "Başlangıç"),
      endDate: getRule(RuleTypes.DATE, "Bitiş"),
      description: getRule(RuleTypes.TEXT100, "Açıklama"),
    })

    const getServicePrice = async id => {
      const { data } = await ApiService.get("service-price/" + id)
      onBusinessChange(data.businessId)

      servicePriceData.value = {
        ...data,
        hasDate: data.startDate != undefined,
      }
    }

    watch(
      () => id.value,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getServicePrice(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined)

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBranchSelectLoading.value = false
    }

    const getSemesterList = async businessId => {
      isSemesterSelectLoading.value = true
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data
      isSemesterSelectLoading.value = false
    }

    const getTimePeriodList = async businessId => {
      isTimePeriodSelectLoading.value = true
      const { data } = await ApiService.get("time-period/business/" + businessId)
      timePeriodList.value = data
      isTimePeriodSelectLoading.value = false
    }
    const getServiceList = async businessId => {
      isServiceSelectLoading.value = true
      const query = {
        params: {
          isWithService: true,
        },
      }
      const { data } = await ApiService.query(
        `service-category/business/${businessId}/childs`,
        query
      )
      serviceList.value = data
      isServiceSelectLoading.value = false
    }

    const onBusinessChange = businessId => {
      servicePriceData.value.branchId = ""
      servicePriceData.value.semesterId = ""
      servicePriceData.value.timePeriodId = ""
      servicePriceData.value.serviceId = ""
      getBranchList(businessId)
      getSemesterList(businessId)
      getTimePeriodList(businessId)
      getServiceList(businessId)
    }

    const updateServicePrice = async () => {
      const data = {
        ...servicePriceData.value,
        startDate: servicePriceData.value.hasDate ? servicePriceData.value.startDate : undefined,
        endDate: servicePriceData.value.hasDate ? servicePriceData.value.endDate : undefined,
        id: props.id,
      }
      await ApiService.post("service-price/edit", data)
      emit("getlist")
      router.push({ name: "service-price-list" })
    }

    function formSubmit(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          updateServicePrice()
        }
      })
    }

    onMounted(() => {
      getBusinessList()
    })

    async function getBusinessList() {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBusinessSelectLoading.value = false
    }

    return {
      showDrawer,
      activeServicePriceId: id,
      handleClose: props.closed,
      servicePriceData,
      formSubmit,
      ruleFormRef,
      businessList,
      branchList,
      semesterList,
      timePeriodList,
      serviceList,
      onBusinessChange,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isSemesterSelectLoading,
      isTimePeriodSelectLoading,
      isServiceSelectLoading,
      serviceTreeSelectProps,
      rules,
    }
  },
})
