
import ApiService from "@/core/services/ApiService"

import { useRoute, useRouter } from "vue-router"
import { computed, defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { SelectModel, SelectModelInt } from "@/core/models/SelectModel"
import { dateForm, DateFormatTypes } from "@/core/helpers/dateformat"
import { ServicePrice } from "@/core/models/ServicePrice"
import { ElForm, ElTree } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import ServicePriceComponent from "@/components/education/definitions/service-price/service-price.vue"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import swalConfirm from "@/core/helpers/swal-confirm"
import { AxiosRequestConfig } from "axios"
import { ServiceHierarchy } from "@/core/models/ServiceHierarchy"
import { currencyForm } from "@/core/helpers/currencyformat"
import { ServiceTypeEnum } from "../../../../core/enums/enums"

export default defineComponent({
  name: "service-price",
  components: { ServicePriceComponent, ElInputCurrency },
  props: [""],
  setup() {
    const route = useRoute()
    const router = useRouter()

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    watch(
      () => route.params.service_price_id,
      newValue => {
        activeServicePriceId.value = newValue as string
      }
    )

    const treeRef = ref<InstanceType<typeof ElTree>>()

    const rules = ref({
      startDate: getRule(RuleTypes.DATE, "Başlangıç Tarihi"),
      endDate: getRule(RuleTypes.DATE, "Bitiş Tarihi"),
      activeServiceId: getRule(RuleTypes.SELECT, "Satış Türü"),
      activeBranchIdList: getRule(RuleTypes.MULTIPLE_SELECT, "Şube"),
      activeBusinessId: getRule(RuleTypes.SELECT, "İşletme"),
      activeSemesterId: getRule(RuleTypes.SELECT, "Dönem"),
      minPrice: getRule(RuleTypes.NUMBER, "Minimum Tutar"),
      maxPrice: getRule(RuleTypes.NUMBER, "Maksimum Tutar"),
    })

    const serviceTreeSelectProps = {
      label: x => x.name,
      value: "id",
      children: "childs",
      disabled: data => {
        return !data.isService
      },
    }

    const startDate = ref<string>()
    const endDate = ref<string>()

    const servicePriceList = ref<ServicePrice[]>([] as ServicePrice[])
    const isLoading = ref(false)
    const activeServicePriceId = ref<string | undefined>()
    const newServicePrice = ref(false)

    const activeBusinessId = ref<string>()
    const activeBranchIdList = ref<string[]>()
    const activeSemesterId = ref<string>()
    const activeTimePeriodIdList = ref<string[]>()
    const activeServiceId = ref<string>()
    const activeStatus = ref<number>()
    const minPrice = ref<number>()
    const maxPrice = ref<number>()

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const semesterList = ref<SelectModel[]>([])
    const timePeriodList = ref<SelectModel[]>([])
    const serviceList = ref<ServiceHierarchy[]>([])

    const statusList = ref<SelectModelInt[]>([
      {
        id: 0,
        name: "Boşta",
      } as SelectModelInt,
      {
        id: 1,
        name: "Açık",
      } as SelectModelInt,
      {
        id: 2,
        name: "Kapalı",
      } as SelectModelInt,
    ])

    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isSemesterSelectLoading = ref(false)
    const isTimePeriodSelectLoading = ref(false)
    const isServiceSelectLoading = ref(false)

    async function getServicePriceList() {
      if (!activeBusinessId.value) return
      const config = {
        params: {},
      } as AxiosRequestConfig
      const businessIdList: string[] = []
      businessIdList.push(activeBusinessId.value)

      const semesterIdList: string[] = []
      semesterIdList.push(activeSemesterId.value as string)

      if (activeBusinessId.value) config.params.businessIds = businessIdList
      if (activeBranchIdList.value) config.params.branchIds = activeBranchIdList.value
      if (activeServiceId.value) config.params.serviceIds = activeServiceId.value
      if (activeSemesterId.value) config.params.semesterIds = semesterIdList
      if (activeTimePeriodIdList.value) config.params.timePeriodIds = activeTimePeriodIdList.value

      isLoading.value = true
      const { data } = await ApiService.query("service-price", config)
      servicePriceList.value = data
      isLoading.value = false
    }

    const servicePriceFilter = computed(() => ({
      activeBusinessId: activeBusinessId.value,
      activeServiceId: activeServiceId.value,
      activeSemesterId: activeSemesterId.value,
      activeBranchIdList: activeBranchIdList.value,
      activeTimePeriodIdList: activeTimePeriodIdList.value,
      startDate: startDate.value,
      endDate: endDate.value,
      minPrice: minPrice.value,
      maxPrice: maxPrice.value,
    }))

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      activeBusinessId.value = undefined
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        onBusinessChange(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
      if (branchList.value.length == 1) {
        activeBranchIdList.value = [branchList.value[0].id]
      }
      isBranchSelectLoading.value = false
    }
    const getSemesterList = async businessId => {
      isSemesterSelectLoading.value = true
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data
      if (semesterList.value.length == 1) {
        activeSemesterId.value = semesterList.value[0].id
      }
      isSemesterSelectLoading.value = false
    }
    const getTimePeriodList = async businessId => {
      isTimePeriodSelectLoading.value = true
      const { data } = await ApiService.get("time-period/business/" + businessId)
      timePeriodList.value = data

      isTimePeriodSelectLoading.value = false
    }
    const getServiceList = async businessId => {
      isServiceSelectLoading.value = true
      activeServiceId.value = undefined
      const query = {
        params: {
          isWithService: true,
        },
      }
      const { data } = await ApiService.query(
        `service-category/business/${businessId}/childs`,
        query
      )
      serviceList.value = data
      isServiceSelectLoading.value = false
    }

    const handleServicePriceDrawerClose = () => {
      router.push({ name: "service-price-list" })
    }

    onMounted(() => {
      activeServicePriceId.value = route.params.service_price_id as string
      getBusinessList()
    })

    const onBusinessChange = businessId => {
      activeBranchIdList.value = undefined
      activeSemesterId.value = undefined
      activeTimePeriodIdList.value = undefined
      activeServiceId.value = undefined
      activeStatus.value = undefined

      branchList.value = []
      semesterList.value = []
      timePeriodList.value = []
      serviceList.value = []

      servicePriceList.value = []
      if (businessId) {
        getBranchList(businessId)
        getSemesterList(businessId)
        getTimePeriodList(businessId)
        getServiceList(businessId)
        getServicePriceList()
      }
    }

    const onSelectChange = () => {
      getServicePriceList()
    }

    const onBranchChange = args => {
      if (args.indexOf(0) > -1) {
        activeBranchIdList.value = branchList.value.map(x => x.id)
      }
      onSelectChange()
    }

    const onTimePeriodChange = args => {
      if (args.indexOf(0) > -1) {
        activeTimePeriodIdList.value = timePeriodList.value.map(x => x.id)
      }
      onSelectChange()
    }

    const removeServicePrice = async servicePriceId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("service-price/remove/" + servicePriceId)
        await getServicePriceList()
      }
    }

    function addServicePrice(formEl) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const data = {
            serviceIds: activeServiceId.value,
            semesterId: activeSemesterId.value,
            branchIds: activeBranchIdList.value,
            timePeriodIds: activeTimePeriodIdList.value,
            minPrice: minPrice.value,
            maxPrice: maxPrice.value,
            startDate: startDate.value,
            endDate: endDate.value,
          }

          await ApiService.post("service-price/add", data)
          getServicePriceList()
        }
      })
    }

    const serviceType = computed(
      () => treeRef.value?.getCurrentNode()?.serviceType as ServiceTypeEnum
    )

    const onServiceChange = () => {
      if (serviceType.value != ServiceTypeEnum.Education) {
        activeTimePeriodIdList.value = []
      }
      onSelectChange()
    }
    return {
      servicePriceList,
      Edit,
      isLoading,
      getBranchList,
      getSemesterList,
      getTimePeriodList,
      getServiceList,
      getServicePriceList,
      activeServicePriceId,
      businessList,
      branchList,
      semesterList,
      timePeriodList,
      serviceList,
      statusList,
      activeBusinessId,
      activeBranchIdList,
      activeSemesterId,
      activeTimePeriodIdList,
      activeServiceId,
      activeStatus,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isSemesterSelectLoading,
      isTimePeriodSelectLoading,
      isServiceSelectLoading,
      handleServicePriceDrawerClose,
      onBusinessChange,
      serviceTreeSelectProps,
      dateForm,
      currencyForm,
      DateFormatTypes,
      removeServicePrice,
      newServicePrice,
      onSelectChange,
      serviceType,
      addServicePrice,
      ruleFormRef,
      minPrice,
      maxPrice,
      startDate,
      endDate,
      rules,
      servicePriceFilter,
      onTimePeriodChange,
      onBranchChange,
      treeRef,
      ServiceTypeEnum,
      onServiceChange,
    }
  },
})
